import React, { useState, useEffect, Fragment } from "react"
import { Router } from "@reach/router"
import useFetch from "../hooks/useFetch"
import { API_URL } from "../utils"
import Helmet from "../components/Helmet"
import Alert from "../components/Alert"
import Content from "../pagesPurchased/Content"
import Hdr from "../components/Hdr"
import Ftr from "../components/Ftr"

const PurchasedPage = () => {
  return (
    <Router>
      <Component path="/purchased/:id" />
    </Router>
  )
}

export default PurchasedPage

const Component = props => {
  const [url, setUrl] = useState(null)
  const [purchased, setPurchased] = useState({})

  const { loading, data } = useFetch(url)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/purchasedInit`)
    const params = {
      id: props.id,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  useEffect(() => {
    if (data && data.resp === 1) {
      setPurchased({ ...data })
    }
  }, [data])

  return (
    <Fragment>
      <Helmet
        title="Purchased Course"
        descr="The Palm Beach County Bar Association (PBCBA) offers CLE hours from the sale of audio MP3’s, MP4’s and CD’s recorded at previously held live seminars of area bar associations."
      />
      <Hdr showCartLink={false} />
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 1 ? (
        <Content purchased={purchased} />
      ) : data.resp === 2 ? (
        <div style={{ marginTop: "25px", marginBottom: "25px" }}>
          <Alert
            data={{
              type: "error",
              text: data.text,
            }}
          />
        </div>
      ) : (
        <div style={{ marginTop: "25px", marginBottom: "25px" }}>
          <Alert data={{ type: "error", text: "Purchased course not found" }} />
        </div>
      )}
      <Ftr />
    </Fragment>
  )
}
