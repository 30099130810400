import React, { useState, useEffect, Fragment } from "react"
import useFetch from "../../hooks/useFetch"
import { API_URL } from "../../utils"

// padding-top calculation is height / width (assuming 16:9 aspect ratio)

const Stream = ({ cfstreamId }) => {
  const [url, setUrl] = useState(null)

  const { loading, data } = useFetch(url)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/streamInit`)
    const params = {
      cfstreamId,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [])

  return (
    <Fragment>
      {loading ? (
        <div>Loading...</div>
      ) : data.resp === 0 ? (
        <div>Error loading data.</div>
      ) : (
        <div
          style={{
            marginTop: "10px",
            position: "relative",
            paddingTop: "56.25%",
          }}
        >
          <iframe
            src={`https://iframe.videodelivery.net/${data.cfstreamToken}`}
            style={{
              border: "none",
              position: "absolute",
              top: "0",
              height: "100%",
              width: "100%",
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen="true"
            title="Stream Video"
          ></iframe>
        </div>
      )}
    </Fragment>
  )
}

export default Stream
