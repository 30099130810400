import React, { useState } from "react"
import { createMarkup } from "../../utils"
import Download from "./Download"
import Stream from "./Stream"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTvAlt } from "@fortawesome/pro-light-svg-icons"

const Content = ({ purchased }) => {
  const [stream, setStream] = useState(false)

  const handleClick = () => {
    setStream(true)
  }

  return (
    <S.Container>
      <S.H2>Purchased Course Details & Assets</S.H2>

      <p style={{ fontSize: "0.95rem" }}>
        Your course media and support materials are listed below (multiple
        formats may be available). Please contact Derek Loiseau at{" "}
        <a href="mailto:dloiseau@palmbeachbar.org ">
          dloiseau@palmbeachbar.org{" "}
        </a>{" "}
        with any questions or for additional information.
      </p>

      <S.H3>{purchased.name}</S.H3>

      <S.Table>
        <thead>
          <tr>
            <th>Course ID</th>
            <th className="hide-on-mobile">Category</th>
            <th>Sponsor</th>
            <th>Course Expires</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="center">{purchased.id}</td>
            <td className="center hide-on-mobile">{purchased.categoryName}</td>
            <td className="center">{purchased.sponsor}</td>
            <td className="center">{purchased.expires}</td>
          </tr>
        </tbody>
      </S.Table>

      <S.Grid>
        <div>
          {purchased.downloads.map((item, index) => {
            return (
              <Download
                data={{ courseId: purchased.id, ...item }}
                key={index}
              />
            )
          })}
        </div>

        <S.Table2>
          <tbody>
            <tr>
              <th className="right">Credit General</th>
              <td>{purchased.credit}</td>
            </tr>
            <tr className={purchased.ethics ? "" : "hidden"}>
              <th className="right">Ethics</th>
              <td>{purchased.ethics}</td>
            </tr>
            <tr className={purchased.professionalism ? "" : "hidden"}>
              <th className="right">Professionalism</th>
              <td>{purchased.professionalism}</td>
            </tr>
            <tr className={purchased.technology ? "" : "hidden"}>
              <th className="right">Technology</th>
              <td>{purchased.technology}</td>
            </tr>
            <tr className={purchased.mental ? "" : "hidden"}>
              <th className="right">Mental Illness</th>
              <td>{purchased.mental}</td>
            </tr>
            <tr className={purchased.bias ? "" : "hidden"}>
              <th className="right">Bias Elimination</th>
              <td>{purchased.bias}</td>
            </tr>
            <tr>
              <th className="right top">Certification Credit</th>

              <td>
                <div className="certif">
                  {purchased.certif.map((item, index) => {
                    return (
                      <div key={index}>
                        <span>{item.credit}</span>
                        <span
                          dangerouslySetInnerHTML={createMarkup(
                            `${item.hours}`
                          )}
                        />
                      </div>
                    )
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </S.Table2>
      </S.Grid>

      {purchased.cfstreamId && (
        <S.StreamContainer>
          {!stream && (
            <button
              type="button"
              className="reset"
              onClick={() => handleClick()}
            >
              <div>
                <FontAwesomeIcon icon={faTvAlt} size="2x" />
                <span>Stream Course Video</span>
                <small>Watch video without downloading</small>
              </div>
            </button>
          )}

          {stream && <Stream cfstreamId={purchased.cfstreamId} />}
        </S.StreamContainer>
      )}
    </S.Container>
  )
}

export default Content
