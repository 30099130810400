import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFilePdf,
  faFileAudio,
  faFileVideo,
  faExternalLinkSquare,
  faArrowSquareDown,
  faCircleNotch,
} from "@fortawesome/pro-light-svg-icons"

function fileName(str, icon) {
  if (icon === "audio") {
    return "course-audio"
  } else if (icon === "video") {
    return "course-video"
  } else {
    const split = str.split(" / ")
    return split[0].replace(/\s+/g, "-").toLowerCase()
  }
}

const Download = ({ data }) => {
  const [downloading, setDownloading] = useState(false)

  /* this might not work on blaze plan
  const handleDownload = url => {
    let xhr = new XMLHttpRequest()
    xhr.responseType = "blob"
    xhr.onload = event => {
      var blob = xhr.response
    }
    xhr.open("GET", url)
    xhr.send()
  }
  */

  const handleDownload = (url, filename = "Document") => {
    if (downloading) {
      return
    } else {
      setDownloading(true)

      fetch(url, {
        headers: {},
      })
        .then(res => res.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = blobUrl
          a.download = filename || "Download"
          a.click()
          URL.revokeObjectURL(blobUrl)
          setDownloading(false)
          return a
        })
    }
  }

  return (
    <div>
      <FontAwesomeIcon
        icon={
          data.icon === "pdf"
            ? faFilePdf
            : data.icon === "audio"
            ? faFileAudio
            : faFileVideo
        }
        size="3x"
      />
      <div>
        <span>{data.descr}</span>
        <div>
          <a href={data.url} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faExternalLinkSquare} size="lg" />
            <span>Open in Browser</span>
          </a>
          <span>/</span>
          <button
            type="button"
            className="naked"
            onClick={() =>
              handleDownload(data.url, fileName(data.descr, data.icon))
            }
          >
            {downloading ? (
              <div style={{ color: "var(--working)" }}>
                <FontAwesomeIcon icon={faCircleNotch} size="lg" spin={true} />
                <span>Downloading...</span>
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={faArrowSquareDown} size="lg" />
                <span>Download File</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Download
