import styled from "styled-components"
import { Table as BaseTable } from "../../styles/styled"

let S = {}
S.Container = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
`

S.H2 = styled.h2`
  margin-top: 15px;
  margin-bottom: 2.5px;
`

S.H3 = styled.h3`
  font-size: 1.1rem;
  margin-top: 20px;
  margin-bottom: 2.5px;
  text-align: center;
`

S.Table = styled(BaseTable)`
  margin-top: 15px;
  margin-bottom: 25px;

  & th {
    width: 25%;

    @media only screen and (max-width: 649px) {
      width: auto;
    }
  }

  & td.hide-on-mobile,
  & th.hide-on-mobile {
    @media only screen and (max-width: 649px) {
      display: none;
    }
  }
`

S.Grid = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  @media only screen and (max-width: 999px) {
    grid-template-columns: 1fr;
  }

  & > div {
    border: 1px solid var(--border);
    padding: 0;

    & > div {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      margin-top: 10px;
      padding: 10px 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--border);

      & > div {
        padding-left: 15px;

        & > span {
          display: block;
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 5px;
        }

        & > div {
          margin-top: 2.5px;
          display: flex;
          align-items: center;
          font-size: 0.9rem;

          & > a {
            display: flex;
            align-items: center;

            & > span {
              padding-left: 5px;
            }
          }

          & > span {
            padding-left: 7.5px;
            padding-right: 10px;
          }

          & > button {
            font-size: 0.9rem;
            color: var(--blue);

            & > div {
              display: flex;
              align-items: center;

              & > span {
                padding-left: 5px;
              }
            }
          }

          & > button:hover {
            color: var(--orange);
          }
        }
      }
    }

    & > div:last-child {
      border-bottom: none;
    }
  }
`

S.Table2 = styled(BaseTable)`
  align-self: start;
  font-size: 0.85rem;
  border: 1px solid var(--border);

  tr.hidden {
    display: none;
  }

  & tr:last-child th,
  & tr:last-child td {
    border-bottom: none;
  }

  & th,
  & td {
    border-bottom: 1px solid var(--border);
    padding: 7.5px;
  }

  & th.colon {
    border-left: none;
  }

  & .certif > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
  }
`

S.StreamContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px auto 25px auto;

  & button {
    width: 100%;
    border: 1px solid var(--border);

    & > div {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--blue);
      font-size: 1.15rem;

      & > span {
        padding-top: 10px;
      }
    }
  }

  & button:hover {
    border-color: var(--orange);
  }

  & button:hover > div {
    color: var(--orange);
  }
`

export default S
